import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStandBy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    style={{
      color: 'inherit',
      ...props.style,
    }}
  >
    <path
      fillRule="evenodd"
      d="m4.16 4.16 1.42 1.42A6.99 6.99 0 0 0 10 18a7 7 0 0 0 4.42-12.42l1.42-1.42a9 9 0 1 1-11.69 0zM9 0h2v8H9V0z"
      fill="currentColor"
    />
  </svg>
);
export default SvgStandBy;
